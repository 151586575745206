import React, { useState, useEffect } from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./reviews.scss";

const Reviews = () => {
  const swiperRef = React.useRef(null);

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function SlidePrevButton() {
    return (
      <div
        className="left_arrow"
        onClick={() => {
          if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
          }
        }}
      >
        <svg
          width="74"
          height="72"
          viewBox="0 0 74 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32.75 70.0666L1.95002 39.2666C1.48335 38.8 1.15202 38.2944 0.956019 37.75C0.763131 37.2055 0.666687 36.6222 0.666687 36C0.666687 35.3778 0.763131 34.7944 0.956019 34.25C1.15202 33.7055 1.48335 33.2 1.95002 32.7333L32.75 1.93331C33.6056 1.07775 34.6742 0.629756 35.956 0.589312C37.2409 0.551979 38.35 0.999977 39.2834 1.93331C40.2167 2.78887 40.7036 3.85753 40.744 5.13931C40.7814 6.4242 40.3334 7.53331 39.4 8.46664L16.5334 31.3333H68.6834C70.0056 31.3333 71.1147 31.7798 72.0107 32.6726C72.9036 33.5686 73.35 34.6778 73.35 36C73.35 37.3222 72.9036 38.4298 72.0107 39.3226C71.1147 40.2186 70.0056 40.6666 68.6834 40.6666H16.5334L39.4 63.5333C40.2556 64.3889 40.7036 65.4778 40.744 66.8C40.7814 68.1222 40.3334 69.2111 39.4 70.0666C38.5445 71 37.4556 71.4666 36.1334 71.4666C34.8111 71.4666 33.6834 71 32.75 70.0666Z"
            fill="#3164F4"
          />
        </svg>
      </div>
    );
  }

  function SlideNextButton() {
    return (
      <div
        className="right_arrow"
        onClick={() => {
          console.log("");
          if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
          }
        }}
      >
        <svg
          width="74"
          height="72"
          viewBox="0 0 74 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.25 70.0666L72.05 39.2666C72.5167 38.8 72.848 38.2944 73.044 37.75C73.2369 37.2055 73.3334 36.6222 73.3334 36C73.3334 35.3778 73.2369 34.7944 73.044 34.25C72.848 33.7055 72.5167 33.2 72.05 32.7333L41.25 1.93331C40.3945 1.07775 39.3258 0.629756 38.044 0.589312C36.7591 0.551979 35.65 0.999977 34.7167 1.93331C33.7834 2.78887 33.2965 3.85753 33.256 5.13931C33.2187 6.4242 33.6667 7.53331 34.6 8.46664L57.4667 31.3333H5.3167C3.99448 31.3333 2.88537 31.7798 1.98937 32.6726C1.09648 33.5686 0.650032 34.6778 0.650032 36C0.650032 37.3222 1.09648 38.4298 1.98937 39.3226C2.88537 40.2186 3.99448 40.6666 5.3167 40.6666H57.4667L34.6 63.5333C33.7445 64.3889 33.2965 65.4778 33.256 66.8C33.2187 68.1222 33.6667 69.2111 34.6 70.0666C35.4556 71 36.5445 71.4666 37.8667 71.4666C39.1889 71.4666 40.3167 71 41.25 70.0666Z"
            fill="#3164F4"
          />
        </svg>
      </div>
    );
  }
  return (
    <section id="reviews" className="reviews">
      <div className="reviews__container">
        <div className="reviews__title">
          <h1>Reviews</h1>
        </div>
        <div className="reviews__body">
          <div className="reviews__body_left">
            <SlidePrevButton />
            <SlideNextButton />
          </div>
          <div className="reviews__body_right">
            <div className="reviews__body_right_swipper">
              <Swiper
                // navigation={{ nextEl: ".right-arrow", prevEl: ".left-arrow" }}
                spaceBetween={30}
                slidesPerView={size.width < 600 ? 1 : 3}
                modules={[Navigation, Pagination]}
                ref={swiperRef}
                centeredSlides={true}
                pagination={size.width < 600 ? true : false}
                loop={true}
              >
                <SwiperSlide>
                  <div className="reviews__body_right_slide">
                    <div className="reviews__body_right_slide_review">
                      <p>
                        Your service is a great example of how to make people's
                        lives easier, simpler, and more interesting. You free up
                        an incredible amount of time for me to get on with my
                        business, my ideas, moving forward.{" "}
                      </p>
                    </div>
                    <div className="reviews__body_right_slide_info">
                      <span>21.02.2023</span>
                      <span>Caleb Crossman</span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="reviews__body_right_slide">
                    <div className="reviews__body_right_slide_review">
                      <p>
                        I can imagine how hard it is to do everything yourself.
                        I want to cheer you up and tell you that you're doing
                        great. Your idea and business is on fire, and with the
                        right marketing, thousands of people will know about it.
                      </p>
                    </div>
                    <div className="reviews__body_right_slide_info">
                      <span>27.01.2023</span>
                      <span>Eric Walker</span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="reviews__body_right_slide">
                    <div className="reviews__body_right_slide_review">
                      <p>
                        The management of the process is top-notch. And so is
                        the auto selection service provided. When people do what
                        they do, it gives a great result. There are no trifles.
                        All the details are worked out before the event.
                      </p>
                    </div>
                    <div className="reviews__body_right_slide_info">
                      <span>16.12.2022</span>
                      <span>Jose Eddington</span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="reviews__body_right_slide">
                    <div className="reviews__body_right_slide_review">
                      <p>
                        Your service is a great example of how to make people's
                        lives easier, simpler, and more interesting. You free up
                        an incredible amount of time for me to get on with my
                        business, my ideas, moving forward.{" "}
                      </p>
                    </div>
                    <div className="reviews__body_right_slide_info">
                      <span>21.02.2023</span>
                      <span>Caleb Crossman</span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="reviews__body_right_slide">
                    <div className="reviews__body_right_slide_review">
                      <p>
                        I can imagine how hard it is to do everything yourself.
                        I want to cheer you up and tell you that you're doing
                        great. Your idea and business is on fire, and with the
                        right marketing, thousands of people will know about it.
                      </p>
                    </div>
                    <div className="reviews__body_right_slide_info">
                      <span>27.01.2023</span>
                      <span>Eric Walker</span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="reviews__body_right_slide">
                    <div className="reviews__body_right_slide_review">
                      <p>
                        The management of the process is top-notch. And so is
                        the auto selection service provided. When people do what
                        they do, it gives a great result. There are no trifles.
                        All the details are worked out before the event.
                      </p>
                    </div>
                    <div className="reviews__body_right_slide_info">
                      <span>16.12.2022</span>
                      <span>Jose Eddington</span>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
