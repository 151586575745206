import React, { useEffect, useState } from "react";

import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

import style from "./Header.module.scss";

import logo from "../../img/logo.svg";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  return (
    <header className={style.header}>
      <div className={style.header__content}>
        <a href="/" className={style.header__content__logo}>
          <img src={logo} alt="Logo" />
        </a>
        <nav
          className={`${style.header__content__nav} ${
            menuOpen && size.width < 768 ? style.isMenu : ""
          }`}
        >
          <ul>
            <li>
              <a href="#services" onClick={menuToggleHandler}>
                Services
              </a>
            </li>
            <li>
              <a href="#advantages" onClick={menuToggleHandler}>
                Advantages
              </a>
            </li>
            <li>
              <a href="#how" onClick={menuToggleHandler}>
                How we work?
              </a>
            </li>
            <li>
              <a href="#reviews" onClick={menuToggleHandler}>
                Reviews
              </a>
            </li>
          </ul>
          <a onClick={menuToggleHandler} href="#contact">
            <button>Contact Us</button>
          </a>
        </nav>
        <div className={style.header__content__toggle}>
          {!menuOpen ? (
            <BiMenuAltRight onClick={menuToggleHandler} />
          ) : (
            <AiOutlineClose onClick={menuToggleHandler} />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
