import React from "react";
import logo from "../../img/logo.svg";
const Footer = () => {
  return (
    <footer>
      <div className="logo">
        <a href="/">
          {" "}
          <img src={logo} alt="Logo" />
        </a>
      </div>
      <div className="menu">
        <a href="#services">Services</a>
        <a href="#advantages">Advantages</a>
        <a href="#how">How we work?</a>
        <a href="#reviews">Reviews</a>
      </div>
      <div className="cop">
        <span>© 2023 link-futures.ae</span>
      </div>
    </footer>
  );
};

export default Footer;
