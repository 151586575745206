import { Contact, Footer, Header, Reviews } from "./components";
import AOS from "aos";

import "aos/dist/aos.css";
import "./styles/App.scss";

import relax from "./img/relaxing.jpg";
import helicopter from "./img/helicopter.jpg";
import dubai from "./img/dubai.jpg";
import champagne from "./img/champagne.jpg";
import food from "./img/food.jpg";
import graphic from "./img/graphic.jpg";
import community from "./img/community.jpg";
import screen from "./img/screen.jpg";

import lifemanage from "./img/lifemanage.png";
import itmanage from "./img/itmanage.png";
import eventmanage from "./img/eventmanage.png";
import how from "./img/how.png";
function App() {
  AOS.init({
    delay: 100,
    duration: 1000,
  });
  return (
    <div className="App">
      <Header />
      <section className="main">
        <div data-aos="fade-up" className="main__container">
          <h1>Inbound and Outbound Tours by LINKFUTURES</h1>
          <div className="main__container-img">
            <img src={relax} />
          </div>
        </div>
      </section>
      <section id="services" className="life">
        <h1 data-aos="fade-up">Experiences</h1>
        <div className="life__container">
          <div data-aos="fade-left" className="life__left">
            <img src={helicopter} alt="helicopter" />
            <img src={dubai} alt="dubai" />
            <img src={champagne} alt="champagne" />
            <img src={food} alt="food" />
          </div>
          <div data-aos="fade-up" className="life__right">
            <p>
              As your trusted tour operator, LINKFUTURES offers a wide range of
              premium tourism experiences:
            </p>
            <ul>
              <li>Historical and Cultural Tourism</li>
              <li>Business (Congress) Tourism</li>
              <li>Sports and Entertainment Tourism</li>
              <li>Water Tourism</li>
              <li>Gastronomic Tourism</li>
              <li>Ecological Tourism</li>
              <li>Hunting and Fishing Tourism</li>
              <li>Luxury Shopping Tourism</li>
            </ul>
            <a href="#contact">
              <button>Contact Us</button>
            </a>
          </div>
        </div>
      </section>
      <section className="it">
        <h1>LINKFUTURES DMCC: Empowering Digital Solutions</h1>
        <div className="it__container">
          <div className="it__left">
            <p>
              At LINKFUTURES DMCC, we are licensed to provide a wide range of
              innovative services that drive digital transformation. Our
              expertise includes:
            </p>
            <ul>
              <li>
                Internet Consultancy: We offer expert advice to enhance your
                online presence and optimize digital strategies.
              </li>
              <li>
                Social Media Applications Development and Management: From
                custom app development to managing your social platforms, we
                help your brand connect and grow in the digital landscape.
              </li>
              <li>
                Computer Graphic Design Services: Our creative team delivers
                eye-catching and professional graphic designs that make your
                brand stand out.
              </li>
              <li>
                Software House: We develop cutting-edge software solutions
                tailored to your business needs, driving efficiency and
                innovation.
              </li>
            </ul>
            <a href="#contact">
              <button>Contact Us</button>
            </a>
          </div>
          <div className="it__right">
            <img src={graphic} alt="IT Management" />
            <img src={community} alt="IT Management" />
            {/* <img src={screen} alt="IT Management" /> */}
          </div>
        </div>
      </section>
      <section id="advantages" className="advantages">
        <div className="advantages__container">
          <div className="advantages__title">
            <h1>Advantages</h1>
          </div>
          <div data-aos="fade-up" className="advantages__cards">
            <div className="advantages__cards_item">
              <h3>Promptly</h3>
              <p>
                Provision of services is carried out within the time frame
                specified by the customer.
              </p>
            </div>
            <div className="advantages__cards_item">
              <h3>Confidential</h3>
              <p>
                All assigned tasks are performed while strictly preserving the
                privacy of each Client.
              </p>
            </div>
            <div className="advantages__cards_item">
              <h3>Comfortably</h3>
              <p>
                Personalized premium support. We select the most suitable and
                profitable solution for each task.
              </p>
            </div>
            <div className="advantages__cards_item">
              <p>
                We take care of every aspect of your tour, ensuring a seamless
                experience. From arranging travel and accommodation to planning
                exclusive events, booking appointments, and much more – we
                handle it all
              </p>
            </div>
            <div className="advantages__cards_item">
              <p>
                At LINKFUTURES, we elevate your lifestyle. Need a personal chef,
                security, insurance, medical care, or a private jet, yacht,
                villa, or castle? We find the best options and manage every
                detail for you.
              </p>
            </div>
            <div className="advantages__cards_item">
              <p>
                Whether it’s an exclusive wedding, a romantic getaway, a unique
                team-building event, or a Michelin-star culinary tour, we
                specialize in creating unforgettable, tailored experiences.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="how" className="how">
        <div className="how__container">
          <div data-aos="fade-up" className="how__left">
            <img src={screen} alt="How We Work?" />
          </div>
          <div data-aos="fade-left" className="how__right">
            <h1>How we work?</h1>

            <ul>
              <li>Only trusted partners and premium performers</li>
              <li>Leave a request online and we will contact you</li>
              <li>You get 100% results from a proven performer</li>
            </ul>
          </div>
        </div>
      </section>
      <Reviews />
      <section id="contact" className="contact">
        <div className="contact__container">
          <div className="contact__title">
            <h1>Contact Us</h1>
          </div>
          <div className="contact__body">
            <Contact />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;
