import React from "react";
import ReactInputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    const id = toast.loading("Sending...");
    try {
      await fetch("../phpmailer/contactus.php", {
        body: JSON.stringify(data),
        method: "POST",
      });

      reset();

      toast.update(id, {
        render: "Success!",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
    } catch (error) {
      toast.update(id, {
        render: error.data.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
    }
  };

  return (
    <form className="flex flex-col mx-2" onSubmit={handleSubmit(onSubmit)}>
      <input
        className="mt-6 rounded-2xl p-4 bg-transparent border border-gray-600 outline-none text-white"
        placeholder="Name"
        type="name"
        {...register("name", {
          required: true,
          validate: (value) => value !== "",
        })}
      />
      {errors.name && <p className="text-red-500 text-sm">Required field</p>}

      <ReactInputMask
        {...register("phone", {
          required: true,
        })}
        type="phone"
        mask="+7 999 999 99 99"
        // maskPlaceholder=""
        name="phone"
        className="mt-6 rounded-2xl p-4 bg-transparent border border-gray-600 outline-none text-white"
        placeholder="Phone number"
      />
      {errors.phone && <p className="text-red-500 text-sm">Required field</p>}

      <input
        className="mt-6 rounded-2xl p-4 bg-transparent border border-gray-600 outline-none text-white"
        placeholder="Email"
        type="email"
        {...register("email", {
          required: true,
          validate: (value) => value !== "",
        })}
      />
      {errors.email && <p className="text-red-500 text-sm">Required field</p>}

      <div className="flex justify-center mt-10">
        <button className="px-14 py-3">Send</button>
      </div>
    </form>
  );
};

export default Contact;
